import React from "react"
import { Header } from "../../components/header"
import { DashboardContainer } from "../../components/dashboard-container"
import { CustomerSearchFilteringMenu } from "../../components/pages/cx-dashboard/customer-search-filtering-menu"
import { ContractSearchFilteringMenu } from "../../components/pages/cx-dashboard/contract-search-filtering-menu"

export default function CxDashboard() {

  return (
    <>
      <Header headerTitle="" currentPage="cx-dashboard" />
      <DashboardContainer>
        <CustomerSearchFilteringMenu />
        <ContractSearchFilteringMenu />
      </DashboardContainer>
    </>
  )
}
