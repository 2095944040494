import React from "react"
import { useCustomerSearchFilteringMenu } from "./use-customer-search-filtering-menu"
import { FilteringOptions } from "../../../dynamic-filtering-menu/filtering-options"

export const CustomerSearchFilteringMenu = () => {

  const {
    customerFilterOptionsFields,
    customerFilterOptionsInputValues,
  } = useCustomerSearchFilteringMenu()

  return (
    <div className="w-4/5 mx-auto px-6 py-6 mb-5 mt-4 bg-white border rounded-md">
        <FilteringOptions
          title="Customer Search"
          filterOptionsInputValues={customerFilterOptionsInputValues}
          fields={customerFilterOptionsFields}
        />
    </div>
  )
}
