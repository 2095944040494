import { useState } from "react"
import {
  getContactsBySearch,
  getCustomersBySearch,
} from "../../../../middleware/middleware-layer"
import { useMsal } from "@azure/msal-react"
import { debounceValue } from "../../../../utils/debounce"
import { useResourceStrings } from "../use-resource-strings"
import { navigate } from "gatsby-link"

export const useCustomerSearchFilteringMenu = () => {
  const {
    accountNoLabel,
    telephoneNoLabel,
  } = useResourceStrings()
  const { accounts, instance } = useMsal()

  // Errors state management
  const defaultErrors = {
    customerName: "",
    fullAddress: "",
    accountNo: "",
    telephone: "",
    companyContact: "",
    contactEmail: "",
  }
  const [errors, setErrors] = useState(defaultErrors)
  const resetErrors = () => setErrors(defaultErrors)

  // State management for filter options selected values
  const defaultInputValues = {
    customerName: null,
    fullAddress: null,
    accountNo: null,
    //contractNo: null,
    telephone: null,
    //purchaseOrder: null,
    companyContact: null,
    contactEmail: null,
  }
  const [customerFilterOptionsInputValues, setCustomerFilterOptionsInputValues] =
    useState<Record<string, any>>(defaultInputValues)
  const resetFilterOptionsInputValues = () =>
    setCustomerFilterOptionsInputValues(defaultInputValues)

  const loadFuctionMap: Record<string, any> = {
    customerName: getCustomersBySearch,
    fullAddress: getCustomersBySearch,
    accountNo: getCustomersBySearch,
    telephone: getCustomersBySearch,
    companyContact: getContactsBySearch,
    contactEmail: getContactsBySearch,
  }

  const loadOptions = (key: string) => async (inputValue: string) => {
    if (inputValue.length >= 3) {
      setErrors({
        ...defaultErrors,
        [key]: "",
      })
      const debouncedInputValue = await debounceValue(inputValue.replace(/'/g, "''"), 1500)
      const loadFunc = loadFuctionMap[key]
      let options = []
      try {
        const results = await loadFunc(
          accounts,
          instance,
          debouncedInputValue,
          key
        )
        switch (key) {
          case "customerName": {
            const customers =
              results?.data?.internalGetCustomersBySearch?.customers || []
            options = customers.map(
              ({ 
                customerName, 
                customerId, 
                accountNo, 
              }: Record<string, any>) => ({
                customerId: customerId,
                label: `${customerName}${accountNo ? ` (${accountNo})` : ""}`,
                value: customerId,
              })
            )
            break
          }
          case "fullAddress": {
            const customers =
              results?.data?.internalGetCustomersBySearch?.customers || []
            options = customers.map(
              ({
                customerName,
                fullAddress,
                customerId,
                accountNo,
              }: Record<string, any>) => ({
                customerId: customerId,
                label: `${customerName}${accountNo ? ` (${accountNo})` : ""}, ${fullAddress}`,
                value: customerId,
              })
            )
            break
          }
          case "accountNo": {
            const customers =
              results?.data?.internalGetCustomersBySearch?.customers || []
            options = customers.map(
              ({
                accountNo,
                customerName,
                customerId,
              }: Record<string, any>) => ({
                customerId: customerId,
                label: `${accountNo || " "}: ${customerName}`,
                value: customerId,
              })
            )
            break
          }
          case "telephone": {
            const customers =
              results?.data?.internalGetCustomersBySearch?.customers || []
            options = customers.map(
              ({
                mainPhone,
                customerName,
                customerId,
                accountNo,
              }: Record<string, any>) => ({
                customerId: customerId,
                label: `${customerName || " "}${accountNo ? ` (${accountNo})` : ""}: ${mainPhone}`,
                value: customerId,
              })
            )
            break
          }
          case "companyContact": {
            const contacts =
              results?.data?.internalGetContactsBySearch?.contacts || []
            options = contacts.map(
              ({
                companyContact,
                customerName,
                customerId,
              }: Record<string, any>) => ({
                customerId: customerId,
                label: `${companyContact || " "}: ${customerName}`,
                value: customerId,
              })
            )
            break
          }
          case "contactEmail": {
            const contacts =
              results?.data?.internalGetContactsBySearch?.contacts || []
            options = contacts.map(
              ({
                contactEmail,
                customerName,
                customerId,
              }: Record<string, any>) => ({
                customerId: customerId,
                label: `${contactEmail || " "}: ${customerName}`,
                value: customerId,
              })
            )
            break
          }
        }
        return options
      } catch (err) {
        console.log(err)
        return []
      }
    } else {
      setErrors({
        ...defaultErrors,
        [key]: "Min 3 characters",
      })
      return []
    }
  }

  const onOptionSelected = (key: string) => (value: Record<string, any>) => {
    navigate(`/cx-dashboard/customer/${value.customerId}`    )
    setCustomerFilterOptionsInputValues({
      ...defaultInputValues,
      [key]: value,
    })
  }

  const customerFilterOptionsFields = [
    {
      id: "customerName",
      type: "asyncSelect",
      label: "Name",
      placeholder: "Name",
      loadOptions: loadOptions("customerName"),
      handleOnChange: onOptionSelected("customerName"),
      error: errors.customerName,
    },
    {
      id: "fullAddress",
      type: "asyncSelect",
      label: "Address",
      placeholder: "Address",
      loadOptions: loadOptions("fullAddress"),
      handleOnChange: onOptionSelected("fullAddress"),
      error: errors.fullAddress,
    },
    {
      id: "accountNo",
      type: "asyncSelect",
      label: accountNoLabel,
      placeholder: accountNoLabel,
      loadOptions: loadOptions("accountNo"),
      handleOnChange: onOptionSelected("accountNo"),
      error: errors.accountNo,
    },
    {
      id: "telephone",
      type: "asyncSelect",
      label: telephoneNoLabel,
      placeholder: telephoneNoLabel,
      loadOptions: loadOptions("telephone"),
      handleOnChange: onOptionSelected("telephone"),
      error: errors.telephone,
    },
    {
      id: "companyContact",
      type: "asyncSelect",
      label: "Contact Name",
      placeholder: "Contact Name",
      loadOptions: loadOptions("companyContact"),
      handleOnChange: onOptionSelected("companyContact"),
      error: errors.companyContact,
    },
    {
      id: "contactEmail",
      type: "asyncSelect",
      label: "Contact Email",
      placeholder: "Contact Email",
      loadOptions: loadOptions("contactEmail"),
      handleOnChange: onOptionSelected("contactEmail"),
      error: errors.contactEmail,
    },
  ]

  const values = Object.values(customerFilterOptionsInputValues).filter(Boolean)
  const selectedCustomerId = values[0]?.value

  return {
    customerFilterOptionsFields,
    customerFilterOptionsInputValues,
    selectedCustomerId,
    resetFilterOptionsInputValues,
    resetErrors,
  }
}
