import React from "react"
import { FilteringOptions } from "../../../dynamic-filtering-menu/filtering-options"
import { useContractSearchFilteringMenu } from "./use-contract-search-filtering-menu"


export const ContractSearchFilteringMenu = () => {

  const {
    contractFilterOptionsFields,
    contractFilterOptionsInputValues,
  } = useContractSearchFilteringMenu()

  return (
    <div className="w-4/5 mx-auto px-6 py-6 mb-5 mt-4 bg-white border rounded-md">
        <FilteringOptions
            title="Contract Search"
            filterOptionsInputValues={contractFilterOptionsInputValues}
            fields={contractFilterOptionsFields}
        />
    </div>
  )
}